/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-children-prop */
/* eslint-disable object-curly-newline */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import Moment from 'react-moment';
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share';
import { useLocation } from '@reach/router';
import remarkGfm from 'remark-gfm';

import Layout from '../components/Layout';
import Seo from '../components/Seo';

import '../components/Sections/Blog/_blog.scss';

const readingTime = require('reading-time/lib/reading-time');

const BlogArticle = ({ data }) => {
  const location = useLocation();
  const { content, title, image, date, ant, desc, metaData } = data.blog;
  const { global } = data;
  const stats = readingTime(content);
  return (
    <>
      <Seo seo={metaData} global={global} />
      <Layout global={global}>
        <div className="blog-wrapper">
          <div className="">
            <h2 className="blog-heading">{title}</h2>
            <p className="blog-description">{desc}</p>
            <div className="blog-title-container">
              <div className="blog-title">
                <img
                  alt={`$ant article`}
                  src={
                    ant?.antImage.url ||
                    'https://ant-strapi-storage-bucket.fra1.digitaloceanspaces.com/antcolony-website/f2b744c0a1500ab0cceb5c247472eaa1.png'
                  }
                  className="blog-antImage"
                />
                <p className="blog-antName">
                  By {ant?.name || 'Ant Colony Team'}
                </p>
                <p className="blog-date">
                  <Moment format="MMM Do">{date}</Moment>
                </p>
                <p className="blog-date">•</p>
                <p className="blog-date">{stats.text}</p>
              </div>
              <div className="social-media">
                <FacebookShareButton url={location.href} quote={desc}>
                  <FacebookIcon size={30} round />
                </FacebookShareButton>
                <TwitterShareButton url={location.href} title={title}>
                  <TwitterIcon size={30} round />
                </TwitterShareButton>
                <LinkedinShareButton
                  url={location.href}
                  title={title}
                  summary={desc}
                >
                  <LinkedinIcon size={30} round />
                </LinkedinShareButton>
              </div>
            </div>
          </div>
          {image.url ? (
            <img
              alt={`${title} article`}
              src={image.url}
              className="blog-image"
            />
          ) : null}
          <ReactMarkdown
            className="blog-content"
            children={content}
            remarkPlugins={[remarkGfm]}
          />
        </div>
      </Layout>
    </>
  );
};

export default BlogArticle;

export const query = graphql`
  query GetFirstLayout($slug: String) {
    global: strapiGlobal {
      jumpToTop {
        title
      }
      id
      metaData {
        title
        description
        image {
          url
        }
      }
      navbar {
        links {
          url
          title
        }
      }
      footer {
        contactIsmir
        footerInfo
        headerEngagementModels
        headerHome
        headerTeam
        headerTestimonials
        textHome
        textEngagementModels
        textTeam
        textTestimonials
        facebookUrl
        instagramUrl
        linkedinUrl
        dribbbleUrl
      }
    }
    blog: strapiBlogs(slug: { eq: $slug }) {
      metaData {
        title
        description
        image {
          url
        }
      }
      content
      desc
      title
      desc
      image {
        url
      }
      date
      ant {
        name
        antImage {
          url
        }
      }
    }
  }
`;
